import React from 'react'
import Layout from '../components/Layout'
import Services from '../components/Services'
import { Helmet } from 'react-helmet'

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Services | Valerie Pasquiou Interiors & Design</title>
      <meta
        name="description"
        content="Description of the 4 services provided by our agency : Interior design, Interior architecture, Space planning consulting, Branding environment 
        design director."
      />
    </Helmet>
    <Services />
  </Layout>
)

export default IndexPage
