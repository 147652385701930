import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { css } from 'glamor'
import g from 'glamorous'

export default () => (
  <StaticQuery
    query={graphql`
      query Services {
        interiorArchitecture: file(
          relativePath: { eq: "interior-architecture-rect.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        interiorDesign: file(relativePath: { eq: "interior-design-4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        spacePlanning: file(
          relativePath: { eq: "space-planning-consultant-3.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        branding: file(
          relativePath: { eq: "branding-environnement-design-direction.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        architecture: file(relativePath: { eq: "architecture2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <H1>Services</H1>
        <Grid>
          <Item>
            <TextCol>
              <H2>Interior architecture</H2>
              <Description>
                Lorem ipsum dolor sit ametted, consectetur adipiscing elit. Duis
                consequat arcu in ut gravida auctor. Fusce luctus dolor
                venenatis, finibus odio vel, convallis sapien. Mauris ut ipsum
                gravida dolor dui. Sed ut perspiciatis unde omnis iste natus
                error sit voluptatem.
              </Description>
              <Description>
                S accusantium doloremque laudantium, totam rem aperiam, eaque
                ipsa quae ab illo inventore veritatis.
              </Description>
            </TextCol>
            <ImageOuter>
              <ImageInner>
                <Img
                  fluid={data.interiorArchitecture.childImageSharp.fluid}
                  alt="Interior architecture service"
                />
              </ImageInner>
            </ImageOuter>
          </Item>

          <Item>
            <TextCol>
              <H2>Interior design</H2>
              <Description>
                Lorem ipsum dolor sit ametted, consectetur adipiscing elit. Duis
                consequat arcu in ut gravida auctor. Fusce luctus dolor
                venenatis, finibus odio vel, convallis sapien. Mauris ut ipsum
                gravida dolor dui. Sed ut perspiciatis unde omnis iste natus
                error sit voluptatem.
              </Description>
            </TextCol>
            <ImageOuter>
              <ImageInner>
                <Img
                  fluid={data.interiorDesign.childImageSharp.fluid}
                  alt="Interior Design"
                />
              </ImageInner>
            </ImageOuter>
          </Item>
          <Item>
            <TextCol>
              <H2>
                <a
                  href="https://www.bassarchitect.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="services-architecture"
                >
                  &nbsp;&nbsp;Architecture&nbsp;&nbsp;
                </a>
              </H2>
              <Description>
                Lorem ipsum dolor sit ametted, consectetur adipiscing elit. Duis
                consequat arcu in ut gravida auctor. Fusce luctus dolor
                venenatis, finibus odio vel, convallis sapien. Mauris ut ipsum
                gravida dolor dui. Sed ut perspiciatis unde omnis iste natus
                error sit voluptatem.
              </Description>
            </TextCol>
            <ImageOuter>
              <ImageInner>
                <Img
                  fluid={data.architecture.childImageSharp.fluid}
                  alt="Architecture"
                />
              </ImageInner>
            </ImageOuter>
          </Item>
          <Item>
            <TextCol>
              <H2>Space planning consulting</H2>
              <Description>
                Lorem ipsum dolor sit ametted, consectetur adipiscing elit. Duis
                consequat arcu in ut gravida auctor. Fusce luctus dolor
                venenatis, finibus odio vel, convallis sapien. Mauris ut ipsum
                gravida dolor dui. Sed ut perspiciatis unde omnis iste natus
                error sit voluptatem.
              </Description>
            </TextCol>
            <ImageOuter>
              <ImageInner>
                <Img
                  fluid={data.spacePlanning.childImageSharp.fluid}
                  alt="Space planning consulting"
                />
              </ImageInner>
            </ImageOuter>
          </Item>
          <Item>
            <TextCol>
              <H2>
                Branding environment <br /> design direction
              </H2>
              <Description>
                Lorem ipsum dolor sit ametted, consectetur adipiscing elit. Duis
                consequat arcu in ut gravida auctor. Fusce luctus dolor
                venenatis, finibus odio vel, convallis sapien. Mauris ut ipsum
                gravida dolor dui. Sed ut perspiciatis unde omnis iste natus
                error sit voluptatem.
              </Description>
            </TextCol>
            <ImageOuter>
              <ImageInner>
                <Img
                  fluid={data.branding.childImageSharp.fluid}
                  alt="Branding Environment design direction"
                />
              </ImageInner>
            </ImageOuter>
          </Item>
        </Grid>
      </Wrapper>
    )}
  />
)

const Wrapper = g.div({
  margin: `6em auto 0`,
  '@media(max-width: 768px)': {
    margin: `0 auto`,
  },
})

const H1 = g.h1({
  '@media(min-width: 768px)': {
    display: `none !important`,
  },
  padding: `0 15px .35em`,
  display: `block`,
  margin: `.8em 0 .3em`,
  fontSize: `2.2em`,
  marginTop: `0`,
})

const Grid = g.div({
  display: `grid`,
  gridTemplateColumns: `48% 48%`,
  gridRow: `auto auto`,
  gridColumnGap: `4%`,
  gridRowGap: `20px`,
  '@media(max-width: 768px)': {
    display: `block`,
  },
})

const Item = g.div({
  position: `relative`,
  boxAlign: `center`,
  marginBottom: `5em`,
  '@media(max-width: 768px)': {
    display: `flex`,
    flexDirection: `column-reverse`,
    marginBottom: `2em`,
  },
})

const TextCol = g.div({
  position: `absolute`,
  zIndex: `1`,
  top: `50%`,
  transform: `translateY(-50%)`,
  flex: `0 0 40%`,
  maxWidth: `40%`,
  width: `40%`,
  backgroundColor: `white`,
  padding: `1em 1em 1.5em 1.2em`,
  borderRadius: `2px`,
  '@media(max-width: 768px)': {
    zIndex: `0`,
    display: `block`,
    position: `relative`,
    padding: `.3em`,
    width: `100%`,
    transform: `none`,
    maxWidth: `93%`,
  },
})

const H2 = g.h2({
  textAlign: `center`,
  fontWeight: `500`,
  letterSpacing: `0.1em`,
  fontSize: `1.5em`,
  marginBottom: `.5em`,
  lineHeight: `1.30em`,
  opacity: `.7`,
  '@media(max-width: 768px)': {
    fontSize: `1.3em`,
    letterSpacing: `0.03em`,
    marginTop: `12px`,
  },
})

const Description = g.div({
  opacity: `.75`,
  fontSize: `1em`,
  fontWeight: `300`,
  letterSpacing: `.01em`,
  lineHeight: `1.65em`,
  marginBottom: `8px`,
  display: `none`, // a remettre
})

const ImageOuter = g.div({
  width: `100%`,
})

const ImageInner = g.div({
  color: `red`,
  marginLeft: `35%`,
  flex: `0 0 65%`,
  maxWidth: `65%`,
  '@media(max-width: 768px)': {
    display: `block`,
    marginLeft: `0`,
    width: `100%`,
    maxWidth: `100%`,
  },
})

const A = g.a({
  color: `inherit`,
})

const TextColRight = g.div({
  position: `absolute`,
  zIndex: `1`,
  top: `50%`,
  transform: `translateY(-50%)`,
  flex: `0 0 40%`,
  maxWidth: `40%`,
  marginLeft: `60%`,
  backgroundColor: `white`,
  padding: `.5em 1em 1em 1.2em`,
  borderRadius: `2px`,
  '@media(max-width: 768px)': {
    width: `93%`,
    maxWidth: `93%`,
    display: `block`,
    //  position: `relative`,
    transform: `none`,
    marginLeft: `0`,
  },
})

const ImageInnerLeft = g.div({
  marginLeft: `0`,
  flex: `0 0 65%`,
  maxWidth: `65%`,
  '@media(max-width: 768px)': {
    display: `block`,
    marginLeft: `0`,
    width: `100%`,
    maxWidth: `100%`,
  },
})
